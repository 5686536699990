import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../static/environment.js"

let Order = () => {
    const paymentSectionRef = useRef(null);
    const [product, setProduct] = useState(null);
    const [feature, setFeature] = useState(null);
    const [userInput, setUserInput] = useState(null);
    const [formField, setFormField] = useState(null);
    const [ffAttribute, setFfAttribute] = useState(null);
    const [counter, setCounter] = useState(null);
    const [myItem, setMyItem] = useState(null);
    const [payment, setPayment] = useState(null);
    const [price, setPrice] = useState(null);
    const [id, setId] = useState(null);
    const [cardPayment, setCardPayment] = useState(null);
    const [ffArray, setFfArray] = useState(null);
    const [phone, setPhone] = useState("+62");
    const [phoneError, setPhoneError] = useState("");
    const [isVoucher, setIsVoucher] = useState(false);
    const [token, setToken] = useState(null);
    const [order, setOrder] = useState({
        userId: "",
        zoneId: "",
        phone: "+62",
        price: "",
        fee: "",
        payment: "",
        total: ""
    })
    const [isLoading, setIsLoading] = useState(false);

    const [selectedPayment, setSelectedPayment] = useState(null);

    const selectPayment = (index, category) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            price: document.getElementById(`${category}${index}`).value,
            payment: document.getElementById(`code${category}${index}`).textContent,
        }));
    };

    const scrollToPaymentSection = () => {
        if (paymentSectionRef.current) {
            paymentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    let ff = []
    let { item } = useParams();

    useEffect(() => {
        axios.get(`${API_URL}/my-product/find/${item}`)
            .then(function (response) {
                const data = response.data;
                setProduct(data);

                axios.get(`${API_URL}/feature/trx/${data.id}`)
                    .then(function (responseFeature) {
                        setFeature(responseFeature.data);
                    })
                    .catch(function (error) {
                        window.alert(error);
                    })

                axios.get(`${API_URL}/user-input/trx/${data.id}`)
                    .then(function (responseUserInput) {
                        setUserInput(responseUserInput.data)
                        responseUserInput.data.map(x => {
                            if (x.userInput.id === 1 && x.label === "") {
                                setIsVoucher(true)
                            }
                        })
                    })
                    .catch(function (error) {
                        window.alert(error);
                    })

                axios.get(`${API_URL}/form-field/${data.id}`)
                    .then(function (responseFormField) {
                        setFormField(responseFormField.data)

                        responseFormField.data.map(x => {
                            let promise = new Promise((resolve, reject) => {
                                axios.get(`${API_URL}/ff-attribute/${x.id}`)
                                    .then(function (responseFfAttribute) {
                                        var object = responseFfAttribute.data.reduce(
                                            (obj, item) => Object.assign(obj, { [item.key_]: item.value_ }), {}
                                        );
                                        var filtered = responseFfAttribute.data.filter(x => x.key_ === "name");
                                        object.id = filtered[0].id;
                                        object.element = x.type;

                                        resolve(object);
                                    })
                                    .catch(function (error) {
                                        reject(error);
                                    })
                            });
                            ff.push(promise);
                        })

                        Promise.all(ff).then(function (ffa) {
                            ffa.map(x => {
                                if(x.element === "Dropdown") {
                                    JSON.parse(x.datas).map((y, index) => {
                                        if(index === 0) {
                                            setOrder({
                                                ...order,
                                                zoneId: y.value
                                            })
                                        }
                                    })
                                }
                            })
                            setFfAttribute(ffa)
                            setCounter(ffa.length)
                        }).catch(function (error) {
                            window.alert(error);
                        });
                    })
                    .catch(function (error) {
                        window.alert(error);
                    })

                axios.get(`${API_URL}/my-item/by-product/${data.id}`)
                    .then(function (responseMyItem) {
                        setMyItem(responseMyItem.data)
                    })
                    .catch(function (error) {
                        window.alert(error);
                    })

            })
            .catch(function (error) {
                window.alert(error);
            })

        axios.get(`${API_URL}/parameter/API-BANK-KEY`)
            .then(function (response) {
                setToken(response.data.value_);
            })
            .catch(function (error) {
                window.alert(error);
            })
    }, [])

    useEffect(() => {
        callProduct();
    }, [id, price])

    const handleFocus = () => {
        if (!phone.startsWith("+62")) {
            setPhone("+62");
        }
    };

    const handlePrefix = (e) => {
        let value = e.target.value;

        if (!value.startsWith("+62")) {
            value = "+62" + value.replace(/^\+*/, '');
        }

        value = value.replace(/[^\d+]/g, '');

        let prefix = "+62";
        let numberPart = value.slice(3);

        if (numberPart.startsWith("0")) {
            numberPart = numberPart.replace(/^0+/, '');
        }

        const formattedPhone = prefix + numberPart;
        setPhone(formattedPhone);

        if (numberPart.length < 9) {
            setPhoneError("minimal 10 digit");
        } else {
            setPhoneError("");
        }

        setOrder({
            ...order,
            [e.target.name]: value,
        });
    };

    let callProduct = () => {
        axios.get(`${API_URL}/channel/`)
            .then(function (response) {
                setPayment(response.data);
            })
            .catch(function (error) {
                window.alert(error);
            })
    }

    let submit = () => {
        let data = {
            number: order.phone,
            payment: order.payment,
            myItem: id
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-TOKEN-AUTH': token,
            },
        };

        if (isVoucher) {
            axios.post(`${API_URL}/order/process-to-app/`, JSON.stringify(data), config)
                .then(function (response) {
                    post("this is voucher");
                })
                .catch(function (error) {
                    window.alert(error);
                })
        } else if (order.zoneId == null || order.zoneId == "") {
            if (counter > 1) {
            } else {
                post(clearance(order.userId));
            }
        } else {
            post(clearance(order.userId) + clearance(order.zoneId));
        }
    }

    let clearance = (str) => {
        let trimmedStr = str.trim();
        return trimmedStr;
    }

    let post = async (account) => {
        setIsLoading(true);
        if (account === "this is voucher") {
            let data = {
                number: order.phone,
                payment: order.payment,
                myItem: id
            }

            await axios
                .post(`${API_URL}/order/process-to-app`, data, {
                    headers: { 'X-TOKEN-AUTH': token, 'Content-Type': 'application/json' }
                })
                .then((response) => {
                    if (response.data === 'goodbye') {
                        // Do nothing
                    } else {
                        window.location.href = `/history/${phone}/${response.data}`;
                    }
                })
                .catch((error) => {
                    window.alert(error);
                })
                .finally(() => {
                    // hideLoading();
                });
        } else {

            await axios.get(`${API_URL}/my-product/check-id/${id}/${account}`, {
                headers: { 'X-TOKEN-AUTH': token }
            }).then((response) => {
                if (response.data) {
                    let data = {
                        number: order.phone,
                        payment: order.payment,
                        myItem: id
                    }

                    axios
                        .post(`${API_URL}/order/process-to-app`, data, {
                            headers: { 'X-TOKEN-AUTH': token, 'Content-Type': 'application/json' }
                        })
                        .then((response) => {
                            if (response.data === 'goodbye') {
                                // Do nothing
                            } else {
                                let trx = [];

                                if (ffAttribute.length > 1) {
                                    if (ffAttribute[0].element === 'Input' && ffAttribute[1].element === 'Input') {
                                        trx = [
                                            {
                                                id: 0,
                                                value_: order.userId,
                                                ffattribute: document.getElementById('userInputId0').value,
                                                purchase: response.data,
                                            },
                                            {
                                                id: 0,
                                                value_: order.zoneId,
                                                ffattribute: document.getElementById('userInputId1').value,
                                                purchase: response.data,
                                            }
                                        ];
                                    } else {
                                        trx = [
                                            {
                                                id: 0,
                                                value_: order.userId,
                                                ffattribute: document.getElementById('userInputId0').value,
                                                purchase: response.data,
                                            },
                                            {
                                                id: 0,
                                                value_: order.zoneId,
                                                ffattribute: document.getElementById('zoneInputId').value,
                                                purchase: response.data,
                                            }
                                        ];
                                    }
                                } else {
                                    trx = [
                                        {
                                            id: 0,
                                            value_: order.userId,
                                            ffattribute: document.getElementById('userInputId0').value,
                                            purchase: response.data,
                                        }
                                    ];
                                }

                                axios
                                    .post(`${API_URL}/order/transaction-trx-data-user`, trx, {
                                        headers: { 'X-TOKEN-AUTH': token, 'Content-Type': 'application/json' }
                                    })
                                    .then((responsetrx) => {
                                        if (responsetrx.data) {
                                            window.location.href = `/history/${phone}/${response.data}`;
                                        } else {
                                            window.alert("silahkan order ulang")
                                        }
                                    })
                                    .catch((error) => {
                                        window.alert(error);
                                    }).finally(() => {
                                        setIsLoading(false);
                                    })
                            }
                        })
                        .catch((error) => {
                            window.alert(error);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                } else {
                    window.alert("account not valid")
                }
            }).catch((error) => {
                window.alert(error);
            }).finally(() => {
                setIsLoading(false);
            });

        }

    }

    let playerData = () => {
        if (ffAttribute && ffAttribute.length === 2) {
            return (
                <div className="d-flex flex-column row-gap-1" id="playerData">
                    <h6 className="fw-bold divider">Player Data</h6>
                    <div class="d-flex justify-content-between">
                        <h6 class="opacity-75">User ID</h6>
                        <h6 class="fw-bold" id="user">{order.userId}</h6>
                    </div>
                    <div class="d-flex justify-content-between">
                        <h6 class="opacity-75">Server ID</h6>
                        <h6 class="fw-bold" id="zone">{order.zoneId}</h6>
                    </div>
                </div>
            )
        } else if (ffAttribute && ffAttribute.length === 1) {
            return (
                <div className="d-flex flex-column row-gap-1" id="playerData">
                    <h6 className="fw-bold divider">Player Data</h6>
                    <div class="d-flex justify-content-between">
                        <h6 class="opacity-75">User ID</h6>
                        <h6 class="fw-bold" id="user">{order.userId}</h6>
                    </div>
                </div>
            )
        }
    }

    let handleChange = (event) => {
        const { id, value } = event.target;
        setOrder({
            ...order,
            [id]: value,
        });
    }

    let isButtonDisabled;
    if (isVoucher) {
        isButtonDisabled = order.phone === "+62" || order.phone.slice(3).length < 10 || !order.price || !order.payment;
    } else {
        if (counter > 1) {
            isButtonDisabled = !order.userId || !order.zoneId || order.phone === "+62" || order.phone.slice(3).length < 10 || !order.price || !order.payment;
        } else {
            isButtonDisabled = !order.userId || order.phone === "+62" || order.phone.slice(3).length < 10 || !order.price || !order.payment;
        }
    }

    return (
        <>
            <section id="hero">
                {product && feature ? (
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-9 order-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="400">
                                <div id="container" className="d-flex flex-column row-gap-4 w-100">
                                    {userInput && userInput.map((data, index) => {
                                        if (data.userInput.id === 1 && data.label === "") {
                                            return (
                                                ""
                                            )
                                        } else {
                                            return (
                                                <div className="col-12 product-page">
                                                    <div className="card product-corner">
                                                        <div className="card-header bg-secondary">
                                                            <div className="d-flex align-items-center">
                                                                <h5 className="fw-bold m-0 ">{data.label}</h5>
                                                            </div>
                                                        </div>
                                                        <div id={"body" + index} className="card-body">
                                                            {/* {(data.label === "" && data.userInput.id === 1) ? setIsVoucher(true) : ""} */}
                                                            {(data.userInput.id === 1) ?
                                                                (<div id="account" className="row">
                                                                    {
                                                                        ffAttribute && ffAttribute.sort((a, b) => a.name.localeCompare(b.name)).map((x, index) => {
                                                                            if (x.element === "Input") {
                                                                                return (
                                                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 order-1">
                                                                                        <div className="form-floating">
                                                                                            <input id={"userInputId" + index} value={x.id} hidden />
                                                                                            <input type={x.type} className="form-control product-corner" id={x.name} onChange={(e) => handleChange(e)} placeholder="" />
                                                                                            <label htmlFor={x.name}>{x.placeholder}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 order-1">
                                                                                        <div className="form-floating">
                                                                                            <input id="zoneInputId" value={x.id} hidden />
                                                                                            <select id={x.name} className="form-control product-corner" name={x.name} onChange={(e) => handleChange(e)}>
                                                                                                {x.datas && JSON.parse(x.datas).map((option, index) => {
                                                                                                    return (
                                                                                                        <option value={option.value}>{option.text}</option>
                                                                                                    )
                                                                                                })}
                                                                                            </select>
                                                                                            <label htmlFor={x.name}>{x.placeholder}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                </div>) : ""
                                                            }
                                                            {(data.userInput.id === 2) ?
                                                                (<div id="product-price" className="row product-price custom-scroll">
                                                                    {myItem && myItem.map((x, key) => {
                                                                        return (
                                                                            <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                                                                                <input type="text" id={"id" + key} value={x.id} hidden />
                                                                                <input type="radio" name="radio-price" id={"price" + key} value={x.sellPrice} hidden />
                                                                                <label className="card card-price product-corner" for={"price" + key} onClick={() => {
                                                                                    setPrice(x.sellPrice)
                                                                                    setId(x.id)
                                                                                    scrollToPaymentSection()
                                                                                }}>
                                                                                    <div className="card-body">
                                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                                            <div className="product-price">
                                                                                                <h6 className="fw-bold m-0">{x.name}</h6>
                                                                                                <h6 className="text-muted mt-3">{x.sellPrice.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                                                            </div>
                                                                                            <img src={x.image.path} className="img-product" />
                                                                                        </div>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>) : ""}
                                                            {(data.userInput.id === 3) ? (
                                                                <div ref={paymentSectionRef} id="payment-section" class="faq">
                                                                    <div class="faq-list">
                                                                        <ul id="ulProduct">
                                                                            {(price && payment.filter(x => x.category == "QRIS" && x.active == true).length > 0) ? (
                                                                                <li data-aos="fade-up" class="card product-corner">
                                                                                    <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">
                                                                                        QRIS
                                                                                        <i class="bi bi-chevron-down icon-show"></i>
                                                                                        <i class="bi bi-chevron-up icon-close"></i>
                                                                                    </a>
                                                                                    <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                                                                        <div id="qris" class="row product-price mt-4">
                                                                                            {payment && payment.map((data, index) => {
                                                                                                if (data.category === "QRIS") {
                                                                                                    const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                                                                                    const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'qris';

                                                                                                    return (
                                                                                                        <div className="col-lg-4 col-12" key={index}>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio-payment"
                                                                                                                id={"qris" + index}
                                                                                                                value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                                                                                                checked={isSelected}
                                                                                                                onChange={() => selectPayment(index, 'qris')}
                                                                                                                disabled={isDisabled}
                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                            />
                                                                                                            <label
                                                                                                                className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                                                                                                htmlFor={"qris" + index}
                                                                                                            >
                                                                                                                <div className="card-body" onClick={() => selectPayment(index, 'qris')}>
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <div className="product-price">
                                                                                                                            <h6 className="fw-bold m-0" id={"codeqris" + index} hidden>{data.code}</h6>
                                                                                                                            <h6 className="fw-bold m-0" id={"paymentqris" + index}>{data.name}</h6>
                                                                                                                            <small
                                                                                                                                className="text-danger"
                                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                                            >
                                                                                                                                Tidak Tersedia. <br />
                                                                                                                                Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </small>
                                                                                                                            <h6 className="fw-bold mt-3">
                                                                                                                                {Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </h6>
                                                                                                                        </div>
                                                                                                                        <img src={data.icon.path} className="img-product-payment" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            ) : ""}
                                                                            {(price && payment.filter(x => x.category == "Convenience Store" && x.active == true).length > 0) ? (
                                                                                <li data-aos="fade-up" className="card product-corner">
                                                                                    <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-2">
                                                                                        Convenience Store
                                                                                        <i className="bi bi-chevron-down icon-show"></i>
                                                                                        <i className="bi bi-chevron-up icon-close"></i>
                                                                                    </a>
                                                                                    <div id="faq-list-2" className="collapse show" data-bs-parent=".faq-list">
                                                                                        <div id="cs" className="row product-price mt-4">
                                                                                            {payment.map((data, index) => {
                                                                                                if (data.category === "Convenience Store") {
                                                                                                    const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                                                                                    const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'cs';

                                                                                                    return (
                                                                                                        <div className="col-lg-4 col-12" key={index}>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio-payment"
                                                                                                                id={"cs" + index}
                                                                                                                value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                                                                                                checked={isSelected}
                                                                                                                onChange={() => selectPayment(index, 'cs')}
                                                                                                                disabled={isDisabled}
                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                            />
                                                                                                            <label
                                                                                                                className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                                                                                                htmlFor={"cs" + index}
                                                                                                            >
                                                                                                                <div className="card-body" onClick={() => selectPayment(index, 'cs')}>
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <div className="product-price">
                                                                                                                            <h6 className="fw-bold m-0" id={"codecs" + index} hidden>{data.code}</h6>
                                                                                                                            <h6 className="fw-bold m-0" id={"paymentcs" + index}>{data.name}</h6>
                                                                                                                            <small
                                                                                                                                className="text-danger"
                                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                                            >
                                                                                                                                Tidak Tersedia. <br />
                                                                                                                                Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </small>
                                                                                                                            <h6 className="fw-bold mt-3">
                                                                                                                                {Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </h6>
                                                                                                                        </div>
                                                                                                                        <img src={data.icon.path} className="img-product-payment" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            ) : ""}
                                                                            {(price && payment.filter(x => x.category == "E-Wallet" && x.active == true).length > 0) ? (
                                                                                <li data-aos="fade-up" class="card product-corner">
                                                                                    <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-3">
                                                                                        E-Wallet
                                                                                        <i class="bi bi-chevron-down icon-show"></i>
                                                                                        <i class="bi bi-chevron-up icon-close"></i>
                                                                                    </a>
                                                                                    <div id="faq-list-3" class="collapse show" data-bs-parent=".faq-list">
                                                                                        <div id="ewallet" class="row product-price mt-4">
                                                                                            {payment && payment.map((data, index) => {
                                                                                                if (data.category === "E-Wallet") {
                                                                                                    const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                                                                                    const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'wallet';

                                                                                                    return (
                                                                                                        <div className="col-lg-4 col-12">
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio-payment"
                                                                                                                id={"wallet" + index}
                                                                                                                value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                                                                                                checked={isSelected}
                                                                                                                onChange={() => selectPayment(index, 'wallet')}
                                                                                                                disabled={isDisabled}
                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }} />
                                                                                                            <label
                                                                                                                className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                                                                                                htmlFor={"wallet" + index}
                                                                                                            >
                                                                                                                <div className="card-body" onClick={() => selectPayment(index, 'wallet')}>
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <div className="product-price">
                                                                                                                            <h6 className="fw-bold m-0" id={"codewallet" + index} hidden>{data.code}</h6>
                                                                                                                            <h6 className="fw-bold m-0" id={"paymentwallet" + index}>{data.name}</h6>
                                                                                                                            <small
                                                                                                                                className="text-danger"
                                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                                            >
                                                                                                                                Tidak Tersedia.
                                                                                                                                <br />
                                                                                                                                Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </small>
                                                                                                                            <h6 className="fw-bold mt-3">{Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                                                                                        </div>
                                                                                                                        <img src={data.icon.path} className="img-product-payment" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            ) : ""}
                                                                            {(price && payment.filter(x => x.category == "Virtual Account" && x.active == true).length > 0) ? (
                                                                                <li data-aos="fade-up" className="card product-corner">
                                                                                    <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-4">
                                                                                        Virtual Account
                                                                                        <i className="bi bi-chevron-down icon-show"></i>
                                                                                        <i className="bi bi-chevron-up icon-close"></i>
                                                                                    </a>
                                                                                    <div id="faq-list-4" className="collapse show" data-bs-parent=".faq-list">
                                                                                        <div id="va" className="row product-price mt-4">
                                                                                            {payment.map((data, index) => {
                                                                                                if (data.category === "Virtual Account") {
                                                                                                    const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                                                                                    const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'va';

                                                                                                    return (
                                                                                                        <div className="col-lg-4 col-12" key={index}>
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio-payment"
                                                                                                                id={"va" + index}
                                                                                                                value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                                                                                                checked={isSelected}
                                                                                                                onChange={() => selectPayment(index, 'va')}
                                                                                                                disabled={isDisabled}
                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                            />
                                                                                                            <label
                                                                                                                className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                                                                                                htmlFor={"va" + index}
                                                                                                            >
                                                                                                                <div className="card-body" onClick={() => selectPayment(index, 'va')}>
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <div className="product-price">
                                                                                                                            <h6 className="fw-bold m-0" id={"codeva" + index} hidden>{data.code}</h6>
                                                                                                                            <h6 className="fw-bold m-0" id={"paymentva" + index}>{data.name}</h6>
                                                                                                                            <small
                                                                                                                                className="text-danger"
                                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                                            >
                                                                                                                                Tidak Tersedia. <br />
                                                                                                                                Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </small>
                                                                                                                            <h6 className="fw-bold mt-3">
                                                                                                                                {Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </h6>
                                                                                                                        </div>
                                                                                                                        <img src={data.icon.path} className="img-product-payment" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            ) : ""}
                                                                            {(price && payment.filter(x => x.category == "Bank" && x.active == true).length > 0) ? (
                                                                                <li data-aos="fade-up" class="card product-corner">
                                                                                    <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-5">
                                                                                        Bank
                                                                                        <i class="bi bi-chevron-down icon-show"></i>
                                                                                        <i class="bi bi-chevron-up icon-close"></i>
                                                                                    </a>
                                                                                    <div id="faq-list-5" class="collapse show" data-bs-parent=".faq-list">
                                                                                        <div id="bank" class="row product-price mt-5">
                                                                                            {payment && payment.map((data, index) => {
                                                                                                if (data.category === "Bank") {
                                                                                                    const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                                                                                    const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'bank';

                                                                                                    return (
                                                                                                        <div className="col-lg-4 col-12">
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio-payment"
                                                                                                                id={"bank" + index}
                                                                                                                value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                                                                                                checked={isSelected}
                                                                                                                onChange={() => selectPayment(index, 'bank')}
                                                                                                                disabled={isDisabled}
                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                            />
                                                                                                            <label
                                                                                                                className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                                                                                                htmlFor={"bank" + index}
                                                                                                            >
                                                                                                                <div className="card-body">
                                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                                        <div className="product-price">
                                                                                                                            <h6 className="fw-bold m-0" id={"codebank" + index} hidden>{data.code}</h6>
                                                                                                                            <h6 className="fw-bold m-0" id={"paymentbank" + index}>{data.name}</h6>
                                                                                                                            <small
                                                                                                                                className="text-danger"
                                                                                                                                style={{ display: isDisabled ? 'inline' : 'none' }}
                                                                                                                            >
                                                                                                                                Tidak Tersedia. <br />
                                                                                                                                Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                                                                                            </small>
                                                                                                                            <h6 className="fw-bold mt-3">{Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                                                                                        </div>
                                                                                                                        <img src={data.icon.path} className="img-product-payment" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            ) : ""}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            ) : ""}
                                                            {(data.userInput.id === 4) ? (
                                                                <div className="row">
                                                                    <div className="col-lg-9 col-12">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control product-corner" id="floatingInput" placeholder="name@example.com" />
                                                                            <label for="floatingInput">Code Promo</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-12">
                                                                        <button className="btn btn-primary product-corner h-100 w-100">Apply Code</button>
                                                                    </div>
                                                                </div>
                                                            ) : ""}
                                                            {(data.userInput.id === 5) ? (
                                                                <div id="numberPhone" class="row">
                                                                    <div class="col">
                                                                        <div class="form-floating">
                                                                            <input
                                                                                type="text"
                                                                                class="form-control product-corner"
                                                                                id="phone"
                                                                                name="phone"
                                                                                placeholder=""
                                                                                value={order.phone}
                                                                                onFocus={handleFocus}
                                                                                onInput={handlePrefix}
                                                                                onChange={handlePrefix} />
                                                                            <label for="phone">Nomor Whatsapp (cth: +628XXXXXXXXX)</label>
                                                                            {phoneError && <div id="phone-error" style={{ color: 'red' }}>{phoneError}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : ""}
                                                        </div>
                                                        {(data.instruction !== "") ? (
                                                            <div id="instruction" className="card-body">
                                                                <label>{data.instruction}</label>
                                                            </div>
                                                        ) : ""}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                    <div className="d-grid">
                                        <input type="button" id="submitBtn" className="btn btn-lg btn-primary product-corner py-3 fs-6 fw-bold"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal" value="Order Now" disabled={isButtonDisabled} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 order-1 hero-img" data-aos="zoom-out" data-aos-delay="300">
                                <div className="card product-corner">
                                    <div className="card-body">
                                        <img src={product.logo.path} alt="" className="img-fluid product-corner" />
                                        <div className="text-center mt-3">
                                            <h6 className="opacity-50">{product.sub_title}</h6>
                                            <h2><span className="lh-base text-dark fw-bold">{product.title}</span></h2>
                                        </div>
                                        <div className="mt-3" style={{ display: "flex", flexWrap: "wrap" }}>
                                            {feature.map(x => {
                                                if (x.active) {
                                                    return (
                                                        <div style={{ flex: "1 1 auto", minWidth: "100px", textAlign: "left", fontSize: "12px" }}>{x.feature.name}</div>
                                                    )
                                                }
                                            })}
                                        </div>
                                        <hr className="opacity-25" />
                                        <div className="text-start mt-3">
                                            <h6 className="fw-bold">Description</h6>
                                            <p id="descriptionProduct" className="lh-sm fw-normal opacity-75" dangerouslySetInnerHTML={{ __html: product.description }}></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <h1>loading</h1>}
            </section>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex flex-column">
                                <h1 class="modal-title fs-5 fw-bold" id="orderModalLabel">Detail Order</h1>
                                <p class="m-0 opacity-75">If your order is correct, click Order Now</p>
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body d-flex flex-column row-gap-4">
                            {playerData()}
                            <div class="d-flex flex-column row-gap-1">
                                <h6 class="fw-bold divider">Summary Buyer</h6>
                                <div class="d-flex flex-column row-gap-2">
                                    <div class="d-flex justify-content-between">
                                        <h6 class="opacity-75">Number Phone</h6>
                                        <h6 class="fw-bold" id="phoneOrder">{order.phone}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <h6 class="opacity-75">Price</h6>
                                        <h6 class="fw-bold" id="priceOrder">{price?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <h6 class="opacity-75">Fee</h6>
                                        <h6 class="fw-bold" id="feeOrder">{(order.price - price)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <h6 class="opacity-75">Payment Method</h6>
                                        <h6 class="fw-bold" id="paymentOrder">{order.payment}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <h6 class="fw-bold">Total Price</h6>
                                        <h6 class="fw-bold" id="totalPriceOrder">{Number(order.price)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" data-bs-dismiss="modal">Cancel</button>

                            <button id="orderNow" type="button" class="btn btn-primary product-corner fw-bold px-3"
                                onClick={() => submit()}>
                                    {isLoading ? (
                                        <div className="spinner-border spinner-border-sm text-light" role="status"></div>
                                    ) : (
                                        "Order Now"
                                    )}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Order;