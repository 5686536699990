import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import moment from 'moment';
import Swal from 'sweetalert2';
import { API_URL } from "../../../static/environment.js"
import "./index.css"
import $ from 'jquery';
import 'datatables.net/js/dataTables.js';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';
import 'datatables.net';

const Profile = () => {
  const paymentSectionRef = useRef(null);
  const [price, setPrice] = useState(null);
  const [id, setId] = useState(null);
  const [username, setUsername] = useState(() => sessionStorage.getItem("username") || "");
  const [usernameChange, setUsernameChange] = useState(() => sessionStorage.getItem("username") || "");
  const [role, setRole] = useState(() => sessionStorage.getItem("role") || "");
  const [phoneNumber, setPhoneNumber] = useState(() => sessionStorage.getItem("phoneNumber") || "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorProfile, setErrorProfile] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [nominal, setNominal] = useState(0);
  const [payment, setPayment] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [order, setOrder] = useState({
    phone: "",
    price: 0,
    payment: "",
    category: ""
  })
  const [membership, setMembership] = useState("")

  useEffect(() => {
    callProduct();
  }, [id, price])

  const selectPayment = (index, category) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      price: document.getElementById(`${category}${index}`).value,
      payment: document.getElementById(`code${category}${index}`).textContent,
      category: category
    }));
  };

  const [activeMenu, setActiveMenu] = useState('Akun');

  const [activeTab, setActiveTab] = useState('profile');

  const criteria = [
    { id: 'length', label: 'Minimal 8 karakter', isValid: newPassword.length >= 8 },
    { id: 'uppercase', label: 'Minimal 1 huruf kapital', isValid: /[A-Z]/.test(newPassword) },
    { id: 'number', label: 'Minimal 1 angka', isValid: /[0-9]/.test(newPassword) },
    { id: 'symbol', label: 'Minimal 1 simbol', isValid: /[!@#$%^&*]/.test(newPassword) }
  ];

  const isPasswordValid = criteria.every((criterion) => criterion.isValid);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('isLoggedIn');

    window.location.href = "/"
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();

    if (usernameChange === "") {
      setErrorProfile('error: nama lengkap kosong');
      return;
    }

    if (phoneNumber === "") {
      setErrorProfile('error: nomor telepon kosong');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/user/update-profile`, {
        name: usernameChange,
        phoneNumber
      });

      if (response.data.message === "Update Berhasil") {
        sessionStorage.setItem("username", usernameChange)
        setUsername(usernameChange)
        Swal.fire({
          icon: 'success',
          title: 'Update Profile Berhasil'
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Update Profile Gagal'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Update Profile Gagal'
      });
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (confirmNewPassword !== newPassword) {
      setErrorPassword("password baru tidak sesuai")
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/user/update-password`, {
        username: phoneNumber,
        oldPassword,
        newPassword
      });

      if (response.data.message === "Update Berhasil") {
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("phoneNumber");
        sessionStorage.removeItem("role");

        let countdown = 5;

        const swalWithTimer = Swal.fire({
          icon: 'success',
          title: 'Update Password Berhasil',
          html: `Silahkan login kembali. <br> Mengarahkan dalam <b>${countdown}</b> detik...`,
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            const timerInterval = setInterval(() => {
              countdown--;
              b.textContent = countdown;
              if (countdown <= 0) {
                clearInterval(timerInterval);
              }
            }, 1000);
          }
        }).then(() => {
          window.location.href = "/";
        });
      }

      if (response.data.message === "Update Gagal") {
        Swal.fire({
          icon: 'error',
          title: 'Update Password Gagal'
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Update Password Gagal'
      });
    }
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonDisabledUpgradeMember, setIsButtonDisabledUpgradeMember] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const topUpMember = async () => {
    setIsLoading(true); // Mulai loading

    try {
      const response = await axios.post(`${API_URL}/topup-member`, {
        paymentMethod: {
          code: order.payment,
          category: order.category
        },
        nominal: nominal,
        user: sessionStorage.getItem("phoneNumber")
      });

      if (response && response.data) {
        window.location.href = `/profile/topup-member/${response.data}`;
      } else {
        alert("Request topup gagal");
      }
    } catch (error) {
      // console.error("Error topup:", error);
      alert("Request topup gagal");
    } finally {
      setIsLoading(false); // Selesai loading
    }
  };

  const upgradeMember = async () => {
    setIsLoading(true); // Mulai loading

    try {
      const response = await axios.post(`${API_URL}/membership/upgrade`, {
        code: order.payment,
        category: order.category,
        membershipId: id,
        user: sessionStorage.getItem("phoneNumber")
      });

      console.log(response)

      // if (response && response.data) {
      //   window.location.href = `/profile/topup-member/${response.data}`;
      // } else {
      //   alert("Request topup gagal");
      // }
    } catch (error) {
      console.error("Error topup:", error);
      alert("Request topup gagal");
    } finally {
      setIsLoading(false); // Selesai loading
    }
  };

  useEffect(() => {
    const phoneNumber = sessionStorage.getItem("phoneNumber");
    if (order.payment && order.category && nominal && phoneNumber) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [order, nominal]);

  useEffect(() => {
    if (order.payment && order.category && price && id) {
      setIsButtonDisabledUpgradeMember(false);
    } else {
      setIsButtonDisabledUpgradeMember(true);
    }
  }, [order, price, id]);

  const formatIDR = (value) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(value || 0);
  };

  const handleNominalChange = (e) => {
    // Ambil hanya angka dari input
    const input = e.target.value.replace(/[^0-9]/g, '');
    const parsedValue = parseInt(input, 10) || 0;
    setNominal(parsedValue);
    setOrder((prevOrder) => ({
      ...prevOrder,
      payment: "",
      price: 0
    }))
  };

  const handleButtonClick = (amount) => {
    setNominal((prev) => prev + amount);
    setOrder((prevOrder) => ({
      ...prevOrder,
      payment: "",
      price: 0
    }))
  };

  const scrollToPaymentSection = () => {
    if (paymentSectionRef.current) {
      paymentSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    axios.get(`${API_URL}/channel/`)
      .then(function (response) {
        setPayment(response.data);
      })
      .catch(function (error) {
        window.alert(error);
      })
  }, [nominal])

  useEffect(() => {
    $('#transactionTable').DataTable({
      serverSide: true,
      processing: true,
      ajax: {
        url: `${API_URL}/transaction`,
        type: 'GET',
        data: (d) => ({
          draw: d.draw,
          start: d.start,
          length: d.length,
          search: d.search?.value || '',
          userLogged: sessionStorage.getItem("number")
        }),
        dataSrc: (json) => json.data
      },
      columns: [
        {
          data: null,
          title: '#',
          render: (data, type, row, meta) => meta.row + 1
        },
        {
          data: 'trxDate',
          title: 'Tanggal',
          render: (data) => moment(data).format('DD MMM YYYY HH:mm')
        },
        { data: 'type', title: 'Tipe Transaksi' },
        {
          data: 'previousBalance',
          title: 'Saldo Sebelum',
          render: (data) => formatIDR(data)
        },
        {
          data: 'finalBalance',
          title: 'Saldo Sesudah',
          render: (data) => formatIDR(data)
        }
      ],
      order: [[1, 'desc']]
    });
  }, [activeMenu])

  useEffect(() => {
    axios.get(`${API_URL}/reseller/check-balance/${sessionStorage.getItem("number")}`)
      .then(function (response) {
        sessionStorage.setItem("saldo", response.data);
      })
      .catch(function (error) {
        window.alert(error);
      })

    axios.get(`${API_URL}/membership`)
      .then(function (responseMyItem) {
        setMembership(responseMyItem.data)
      })
      .catch(function (error) {
        window.alert(error);
      })
  }, [])

  let callProduct = () => {
    axios.get(`${API_URL}/channel/`)
      .then(function (response) {
        setPayment(response.data);
      })
      .catch(function (error) {
        window.alert(error);
      })
  }

  return (
    <section id="hero">
      <div className="container py-5">
        <div data-aos="zoom-out">
          <div className='row'>
            <div className='col-lg-3'>
              <div className="card product-corner">
                <div className="card-body">
                  <ul>
                    <li
                      className={activeMenu === 'Akun' ? 'active' : ''}
                      onClick={() => handleMenuClick('Akun')}
                    >
                      Akun
                    </li>
                    <li
                      className={activeMenu === 'Membership' ? 'active' : ''}
                      onClick={() => handleMenuClick('Membership')}
                    >
                      Membership
                    </li>
                    <li
                      className={activeMenu === 'Riwayat Transaksi' ? 'active' : ''}
                      onClick={() => handleMenuClick('Riwayat Transaksi')}
                    >
                      Riwayat Transaksi
                    </li>
                    <li
                      className={activeMenu === 'Riwayat Mutasi' ? 'active' : ''}
                      onClick={() => handleMenuClick('Riwayat Mutasi')}
                    >
                      Riwayat Mutasi
                    </li>
                    <li
                      onClick={() => handleLogout()}
                    >
                      Keluar
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-9'>
              {activeMenu === "Akun" ? (
                <>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <div className="card mb-3">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <button className="btn btn-primary rounded-circle" style={{ width: '50px', height: '50px' }}>
                              {sessionStorage.getItem("username").charAt(0)}
                            </button>
                            <div className="ml-3" style={{ marginLeft: '10px' }}>
                              <h5>{sessionStorage.getItem("username")}</h5>
                              <p>{sessionStorage.getItem("role")}</p>
                            </div>
                          </div>
                          <button className="btn btn-outline-primary" onClick={() => setActiveMenu("Edit Profile")}>Edit Profile</button>
                        </div>

                        <hr style={{ opacity: 0.25, margin: '0 19px' }} />

                        <div className="d-flex align-items-center" style={{ marginTop: '20px', marginLeft: '19px', marginBottom: '20px' }}>
                          <i className="bi bi-phone fs-5"></i>
                          <p className="ml-2 mb-0 fs-5">{sessionStorage.getItem("phoneNumber")}</p>
                        </div>
                      </div>
                      <div className="card mb-3">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div>
                            <h5>Saldo</h5>
                            <strong><h3>{formatIDR(sessionStorage.getItem("saldo"))}</h3></strong>
                          </div>
                          <div className="text-right">
                            <button className="btn btn-success" onClick={() => setActiveMenu("Topup")}>Top Up</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className="card" style={{
                        backgroundImage: 'url("https://momomlbb.com/images/logo-transparent.png")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        color: 'white',
                        border: 'none',
                        borderRadius: '15px',
                        backgroundColor: 'rgba(173, 216, 230, 0.5)'
                      }}>
                        <div className="card-body" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '15px' }}>
                          <p>Dapatkan harga lebih murah dengan Upgrade Membership!</p>
                          <button className="btn btn-primary blink-btn" onClick={() => setActiveMenu("Membership")}>Upgrade Sekarang</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <h3 className='text-white'>Pesanan Saya</h3>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="row">
                          {/* Kategori Belum Dibayar */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Belum Dibayar</p>
                          </div>

                          {/* Kategori Pending */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Pending</p>
                          </div>

                          {/* Kategori Success */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Success</p>
                          </div>

                          {/* Kategori Expired */}
                          <div className="col-lg-3 text-center">
                            <h2 style={{ fontSize: '50px' }}>0</h2> {/* Ganti 0 dengan jumlah order yang sesuai */}
                            <p className='mb-0' style={{ fontSize: '20px' }}>Expired</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : activeMenu === "Membership" ? (
                <>
                  <div className="card mb-3">
                    <div className="card-body d-flex flex-column">
                      <h4>Upgrade Membership</h4>
                      <hr style={{ opacity: 0.25, margin: '0px 10px 10px 10px' }} />
                      <div id="product-price" className="row product-price custom-scroll">
                        {membership && membership.map((x, key) => {
                          return (
                            <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                              <input type="text" id={"id" + key} value={x.id} hidden />
                              <input type="radio" name="radio-price" id={"price" + key} value={x.registerPrice} hidden />
                              <label className="card card-price product-corner" for={"price" + key} onClick={() => {
                                setPrice(x.registerPrice)
                                setId(x.id)
                                scrollToPaymentSection()
                              }}>
                                <div className="card-body">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="product-price">
                                      <h6 className="fw-bold m-0">{x.name}</h6>
                                      <h6 className="text-muted mt-3">{x.registerPrice.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                    </div>
                                    {/* <img src={x.image.path} className="img-product" /> */}
                                  </div>
                                </div>
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className='card mb-3'>
                    <div className="card-body d-flex flex-column">
                      <h4>Pilih Pembayaran</h4>
                      <hr style={{ opacity: 0.25, margin: '0px 10px' }} />
                    </div>
                    <div ref={paymentSectionRef} id="payment-section" class="faq card-body d-flex flex-column">
                      <div class="faq-list">
                        <ul id="ulProduct">
                          {(price && payment.filter(x => x.category == "QRIS" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" class="card product-corner">
                              <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">
                                QRIS
                                <i class="bi bi-chevron-down icon-show"></i>
                                <i class="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                <div id="qris" class="row product-price mt-4">
                                  {payment && payment.map((data, index) => {
                                    if (data.category === "QRIS") {
                                      const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'qris';

                                      return (
                                        <div className="col-lg-4 col-12" key={index}>
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"qris" + index}
                                            value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'qris')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"qris" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'qris')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codeqris" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentqris" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">
                                                    {Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </h6>
                                                </div>
                                                {/* <img src={data.icon.path} className="img-product-payment" /> */}
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(price && payment.filter(x => x.category == "Convenience Store" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" className="card product-corner">
                              <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-2">
                                Convenience Store
                                <i className="bi bi-chevron-down icon-show"></i>
                                <i className="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-2" className="collapse show" data-bs-parent=".faq-list">
                                <div id="cs" className="row product-price mt-4">
                                  {payment.map((data, index) => {
                                    if (data.category === "Convenience Store") {
                                      const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'cs';

                                      return (
                                        <div className="col-lg-4 col-12" key={index}>
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"cs" + index}
                                            value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'cs')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"cs" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'cs')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codecs" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentcs" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">
                                                    {Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </h6>
                                                </div>
                                                {/* <img src={data.icon.path} className="img-product-payment" /> */}
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(price && payment.filter(x => x.category == "E-Wallet" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" class="card product-corner">
                              <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-3">
                                E-Wallet
                                <i class="bi bi-chevron-down icon-show"></i>
                                <i class="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-3" class="collapse show" data-bs-parent=".faq-list">
                                <div id="ewallet" class="row product-price mt-4">
                                  {payment && payment.map((data, index) => {
                                    if (data.category === "E-Wallet") {
                                      const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'wallet';

                                      return (
                                        <div className="col-lg-4 col-12">
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"wallet" + index}
                                            value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'wallet')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }} />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"wallet" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'wallet')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codewallet" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentwallet" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia.
                                                    <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">{Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                </div>
                                                {/* <img src={data.icon.path} className="img-product-payment" /> */}
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(price && payment.filter(x => x.category == "Virtual Account" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" className="card product-corner">
                              <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-4">
                                Virtual Account
                                <i className="bi bi-chevron-down icon-show"></i>
                                <i className="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-4" className="collapse show" data-bs-parent=".faq-list">
                                <div id="va" className="row product-price mt-4">
                                  {payment.map((data, index) => {
                                    if (data.category === "Virtual Account") {
                                      const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'va';

                                      return (
                                        <div className="col-lg-4 col-12" key={index}>
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"va" + index}
                                            value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'va')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"va" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'va')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codeva" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentva" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">
                                                    {Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </h6>
                                                </div>
                                                {/* <img src={data.icon.path} className="img-product-payment" /> */}
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(price && payment.filter(x => x.category == "Bank" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" class="card product-corner">
                              <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-5">
                                Bank
                                <i class="bi bi-chevron-down icon-show"></i>
                                <i class="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-5" class="collapse show" data-bs-parent=".faq-list">
                                <div id="bank" class="row product-price mt-5">
                                  {payment && payment.map((data, index) => {
                                    if (data.category === "Bank") {
                                      const isDisabled = (price * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'bank';

                                      return (
                                        <div className="col-lg-4 col-12">
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"bank" + index}
                                            value={price * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'bank')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"bank" + index}
                                          >
                                            <div className="card-body">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codebank" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentbank" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">{Math.round(price * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                </div>
                                                {/* <img src={data.icon.path} className="img-product-payment" /> */}
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-3'>
                    <div className="card-body d-flex flex-column">
                      <h4>Informasi Pembayaran</h4>
                      <hr style={{ opacity: 0.25, margin: '0px 10px' }} />

                      <div className="card-body d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                          <p>Nominal Topup</p>
                          <p>Sistem Pembayaran</p>
                          <p>Total Pembayaran</p>
                        </div>

                        <div className="d-flex flex-column text-end">
                          <p>{formatIDR(price)}</p>
                          <p>{order.payment}</p>
                          <p>{formatIDR(order.price)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button
                      id="submitBtn"
                      className="btn btn-lg btn-primary product-corner py-3 fs-6 fw-bold"
                      onClick={upgradeMember}
                      disabled={isButtonDisabledUpgradeMember || isLoading}
                    >
                      {isLoading ? (
                        <div className="spinner-border spinner-border-sm text-light" role="status"></div>
                      ) : (
                        "Order Now"
                      )}
                    </button>
                  </div>
                </>
              ) : activeMenu === "Riwayat Transaksi" ? (
                <>
                  {/* Konten untuk Riwayat Transaksi */}
                  <h1 className='text-dark'>Riwayat Transaksi</h1>
                </>
              ) : activeMenu === "Riwayat Mutasi" ? (
                <>
                  <div class="account-settings" style={{ marginBottom: '20px' }}>
                    <div class="text text-white" style={{ marginLeft: '10px' }}>Riwayat Mutasi</div>
                  </div>
                  <div className="account-settings">
                    <button className="profile-btn">All</button>
                    <button className="profile-btn">Product</button>
                    <button className="profile-btn">Topup</button>
                    <button className="profile-btn">Wallet</button>
                    <button className="profile-btn">Membership</button>
                  </div>
                  <div className='card card-body'>
                    <table id="transactionTable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Tanggal</th>
                          <th>Tipe Transaksi</th>
                          <th>Saldo Sebelum</th>
                          <th>Saldo Sesudah</th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                </>
              ) : activeMenu === "Edit Profile" ? (
                <>
                  <div class="account-settings" style={{ marginBottom: '20px' }}>
                    <div onClick={(e) => setActiveMenu("Akun")} style={{ cursor: "pointer" }}>
                      <svg
                        stroke="currentColor"
                        fill="white"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
                      </svg>
                    </div>
                    <div class="text text-white" style={{ marginLeft: '10px' }}>Pengaturan Akun</div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className="card mb-3">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <button className="btn btn-primary rounded-circle" style={{ width: '50px', height: '50px' }}>
                              {username.charAt(0)}
                            </button>
                            <div className="ml-3" style={{ marginLeft: '10px' }}>
                              <h5>{username}</h5>
                              <p>{role}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="account-settings">
                        <button className="profile-btn" onClick={() => handleTabClick('profile')}>
                          <i className="bi bi-person-circle"></i> Profile
                        </button>
                        <button className="password-btn" onClick={() => handleTabClick('password')}>
                          <i className="bi bi-lock"></i> Password
                        </button>
                      </div>

                      {activeTab === 'profile' && (
                        <div className="card mb-3">
                          <div className="card-body d-flex flex-column">
                            <form onSubmit={(e) => handleProfileSubmit(e)}>
                              <div className="mb-3">
                                <label htmlFor="fullName" className="form-label">Nama Lengkap</label>
                                <input
                                  type="text"
                                  id="fullName"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan nama lengkap"
                                  value={usernameChange}
                                  onChange={(e) => setUsernameChange(e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="phoneNumber" className="form-label">Nomor Handphone</label>
                                <input
                                  type="tel"
                                  id="phoneNumber"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan nomor handphone"
                                  value={phoneNumber}
                                  onChange={(e) => setPhoneNumber(e.target.value)}
                                  disabled
                                />
                              </div>
                              {errorProfile && <p className="error-message">{errorProfile}</p>}
                              <button type="submit" className="btn btn-primary rounded-pill shadow-sm">
                                Update Profile
                              </button>
                            </form>
                          </div>
                        </div>
                      )}

                      {activeTab === 'password' && (
                        <div className="card mb-3">
                          <div className="card-body d-flex flex-column">
                            <form onSubmit={(e) => handleChangePassword(e)}>
                              <div className="mb-3">
                                <label htmlFor="oldPassword" className="form-label">Password Lama</label>
                                <input
                                  type="password"
                                  id="oldPassword"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan password lama"
                                  value={oldPassword}
                                  onChange={(e) => setOldPassword(e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="newPassword" className="form-label">Password Baru</label>
                                <input
                                  type="password"
                                  id="newPassword"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Masukkan password baru"
                                  value={newPassword}
                                  onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <ul className="password-criteria">
                                  {criteria.map((criterion) => (
                                    <li
                                      key={criterion.id}
                                      className={criterion.isValid ? 'text-success' : 'text-danger'}
                                      style={{ padding: "10px 15px" }}
                                    >
                                      {criterion.label}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Konfirmasi Password</label>
                                <input
                                  type="password"
                                  id="confirmPassword"
                                  className="form-control rounded-pill shadow-sm border-0"
                                  placeholder="Konfirmasi password baru"
                                  value={confirmNewPassword}
                                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                              </div>
                              {errorPassword && <p className="error-message">{errorPassword}</p>}
                              <button
                                type="submit"
                                className="btn btn-primary rounded-pill shadow-sm"
                                disabled={!isPasswordValid}
                              >
                                Update Password
                              </button>

                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : activeMenu === "Topup" ? (
                <>
                  <div class="account-settings" style={{ marginBottom: '20px' }}>
                    <div onClick={(e) => setActiveMenu("Akun")} style={{ cursor: "pointer" }}>
                      <svg
                        stroke="currentColor"
                        fill="white"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
                      </svg>
                    </div>
                    <div class="text text-white" style={{ marginLeft: '10px' }}>Top Up</div>
                  </div>
                  <div className="card mb-4">
                    <div className='col-md-12'>
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <div>
                          <h5>Saldo</h5>
                          <strong><h3>{formatIDR(sessionStorage.getItem("saldo"))}</h3></strong>
                        </div>
                        <div className="text-right">
                          <div class="text text-black" style={{ marginLeft: '10px' }}>Riwayat Topup</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className='text text-white'>Top Up Saldo</h2>
                  <div className="card mb-3">
                    <div className="card-body d-flex flex-column">
                      <h4>Nominal Top Up</h4>
                      <hr style={{ opacity: 0.25, margin: '0px 10px' }} />
                      <div className="mt-3">
                        <input
                          type="text"
                          id="nominal"
                          className="form-control rounded-pill shadow-sm border-0"
                          value={formatIDR(nominal)}
                          onChange={handleNominalChange}
                        />
                      </div>
                    </div>
                    <div className="row col-md-6 card-body d-flex">
                      <div className="col-md-4">
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => handleButtonClick(20000)}
                        >
                          Rp. 20.000
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => handleButtonClick(50000)}
                        >
                          Rp. 50.000
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => handleButtonClick(100000)}
                        >
                          Rp. 100.000
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-3'>
                    <div className="card-body d-flex flex-column">
                      <h4>Pilih Pembayaran</h4>
                      <hr style={{ opacity: 0.25, margin: '0px 10px' }} />
                    </div>
                    <div id="payment-section" class="faq card-body d-flex flex-column">
                      <div class="faq-list">
                        <ul id="ulProduct">
                          {(nominal && payment.filter(x => x.category == "QRIS" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" class="card product-corner">
                              <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">
                                QRIS
                                <i class="bi bi-chevron-down icon-show"></i>
                                <i class="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                <div id="qris" class="row product-price mt-4">
                                  {payment && payment.map((data, index) => {
                                    if (data.category === "QRIS") {
                                      const isDisabled = (nominal * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'qris';

                                      return (
                                        <div className="col-lg-4 col-12" key={index}>
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"qris" + index}
                                            value={nominal * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'qris')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"qris" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'qris')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codeqris" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentqris" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">
                                                    {Math.round(nominal * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </h6>
                                                </div>
                                                <img src={data.icon.path} className="img-product-payment" />
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(nominal && payment.filter(x => x.category == "Convenience Store" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" className="card product-corner">
                              <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-2">
                                Convenience Store
                                <i className="bi bi-chevron-down icon-show"></i>
                                <i className="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-2" className="collapse show" data-bs-parent=".faq-list">
                                <div id="cs" className="row product-price mt-4">
                                  {payment.map((data, index) => {
                                    if (data.category === "Convenience Store") {
                                      const isDisabled = (nominal * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'cs';

                                      return (
                                        <div className="col-lg-4 col-12" key={index}>
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"cs" + index}
                                            value={nominal * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'cs')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"cs" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'cs')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codecs" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentcs" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">
                                                    {Math.round(nominal * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </h6>
                                                </div>
                                                <img src={data.icon.path} className="img-product-payment" />
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(nominal && payment.filter(x => x.category == "E-Wallet" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" class="card product-corner">
                              <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-3">
                                E-Wallet
                                <i class="bi bi-chevron-down icon-show"></i>
                                <i class="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-3" class="collapse show" data-bs-parent=".faq-list">
                                <div id="ewallet" class="row product-price mt-4">
                                  {payment && payment.map((data, index) => {
                                    if (data.category === "E-Wallet") {
                                      const isDisabled = (nominal * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'wallet';

                                      return (
                                        <div className="col-lg-4 col-12">
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"wallet" + index}
                                            value={nominal * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'wallet')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }} />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"wallet" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'wallet')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codewallet" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentwallet" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia.
                                                    <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">{Math.round(nominal * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                </div>
                                                <img src={data.icon.path} className="img-product-payment" />
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(nominal && payment.filter(x => x.category == "Virtual Account" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" className="card product-corner">
                              <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-4">
                                Virtual Account
                                <i className="bi bi-chevron-down icon-show"></i>
                                <i className="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-4" className="collapse show" data-bs-parent=".faq-list">
                                <div id="va" className="row product-price mt-4">
                                  {payment.map((data, index) => {
                                    if (data.category === "Virtual Account") {
                                      const isDisabled = (nominal * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'va';

                                      return (
                                        <div className="col-lg-4 col-12" key={index}>
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"va" + index}
                                            value={nominal * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'va')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"va" + index}
                                          >
                                            <div className="card-body" onClick={() => selectPayment(index, 'va')}>
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codeva" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentva" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">
                                                    {Math.round(nominal * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </h6>
                                                </div>
                                                <img src={data.icon.path} className="img-product-payment" />
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                          {(nominal && payment.filter(x => x.category == "Bank" && x.active == true).length > 0) ? (
                            <li data-aos="fade-up" class="card product-corner">
                              <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-5">
                                Bank
                                <i class="bi bi-chevron-down icon-show"></i>
                                <i class="bi bi-chevron-up icon-close"></i>
                              </a>
                              <div id="faq-list-5" class="collapse show" data-bs-parent=".faq-list">
                                <div id="bank" class="row product-price mt-5">
                                  {payment && payment.map((data, index) => {
                                    if (data.category === "Bank") {
                                      const isDisabled = (nominal * (1 + (data.feePercent / 100)) + data.feeFlat) < data.minAmount;
                                      const isSelected = selectedPayment && selectedPayment.index === index && selectedPayment.category === 'bank';

                                      return (
                                        <div className="col-lg-4 col-12">
                                          <input
                                            type="radio"
                                            name="radio-payment"
                                            id={"bank" + index}
                                            value={nominal * (1 + (data.feePercent / 100)) + data.feeFlat}
                                            checked={isSelected}
                                            onChange={() => selectPayment(index, 'bank')}
                                            disabled={isDisabled}
                                            style={{ display: isDisabled ? 'inline' : 'none' }}
                                          />
                                          <label
                                            className={`card ${isDisabled ? 'card-price-na' : 'card-price'} product-corner`}
                                            htmlFor={"bank" + index}
                                          >
                                            <div className="card-body">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="product-price">
                                                  <h6 className="fw-bold m-0" id={"codebank" + index} hidden>{data.code}</h6>
                                                  <h6 className="fw-bold m-0" id={"paymentbank" + index}>{data.name}</h6>
                                                  <small
                                                    className="text-danger"
                                                    style={{ display: isDisabled ? 'inline' : 'none' }}
                                                  >
                                                    Tidak Tersedia. <br />
                                                    Minimal {data.minAmount?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                                                  </small>
                                                  <h6 className="fw-bold mt-3">{Math.round(nominal * (1 + (data.feePercent / 100)) + data.feeFlat)?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</h6>
                                                </div>
                                                <img src={data.icon.path} className="img-product-payment" />
                                              </div>
                                            </div>
                                          </label>
                                        </div>
                                      )
                                    }
                                  })}
                                </div>
                              </div>
                            </li>
                          ) : ""}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='card mb-3'>
                    <div className="card-body d-flex flex-column">
                      <h4>Informasi Pembayaran</h4>
                      <hr style={{ opacity: 0.25, margin: '0px 10px' }} />

                      <div className="card-body d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                          <p>Nominal Topup</p>
                          <p>Sistem Pembayaran</p>
                          <p>Total Pembayaran</p>
                        </div>

                        <div className="d-flex flex-column text-end">
                          <p>{formatIDR(nominal)}</p>
                          <p>{order.payment}</p>
                          <p>{formatIDR(order.price)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button
                      id="submitBtn"
                      className="btn btn-lg btn-primary product-corner py-3 fs-6 fw-bold"
                      onClick={topUpMember}
                      disabled={isButtonDisabled || isLoading}
                    >
                      {isLoading ? (
                        <div className="spinner-border spinner-border-sm text-light" role="status"></div>
                      ) : (
                        "Order Now"
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {/* Konten default jika tidak ada yang cocok */}
                </>
              )}
            </div>
          </div>

        </div>
      </div>
    </section>

  );
};

export default Profile;
